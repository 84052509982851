import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import imgL1FeatureOval from "../../assets/image/png/l1-feature-oval.png";
import imgL1FeatureCurve from "../../assets/image/svg/l1-curve-feature.svg";

const ShapeTopRight = styled(Box)`
  position: absolute;
  top: 0;
  right: 0px;
`;

const ShapeBottmRight = styled(Box)`
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  img {
    min-width: 100%;
  }
`;

const FeatureCard = ({
  color = "primary",
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box bg="light" py="25px" px="30px" borderRadius={10} mb={4} {...rest}>
    <Box
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background-color: ${({ theme, color }) =>
          rgba(theme.colors[color], 0.1)};
      `}
    >
      <i className={`icon ${iconName}`}></i>
    </Box>
    <div>
      <Text
        color="heading"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section bg="secondary" className="position-relative">
      <ShapeTopRight
        data-aos="fade-left"
        data-aos-duration="750"
        data-aos-once="true"
      >
        {/*<img src={imgL1FeatureOval} alt="" className="img-fluid" />*/}
      </ShapeTopRight>
      <ShapeBottmRight>
        <img src={imgL1FeatureCurve} alt="" className="img-fluid" />
      </ShapeBottmRight>
      <Container>
        <Row className="align-items-center">
          <Col lg="12" className="order-lg-1">
            <div className="feature-content section-title">
              <Title color="light">9999+ merchants accepting cryptocurrency already</Title>
            </div>
          </Col>
          <Col lg="12" className="order-lg-2 mt-5 mt-lg-0">
            <Row>
              <Col md="3">
                <FeatureCard
                  color="primary"
                  iconName="icon-layout-11"
                  title="Up and running in minutes"
                >
                  We'll have you set up with hosted checkout pages, payment buttons, invoicing, and multiple ecommerce integrations
                </FeatureCard>
              </Col>
              <Col md="3">
                <FeatureCard
                  color="warning"
                  iconName="icon-sidebar-2"
                  title="Stay in control"
                >
                  We don't hold your crypto. You do. We give you all the benefits of a hosted platform while you maintain ownership.
                </FeatureCard>
              </Col>
              <Col md="3">
                <FeatureCard
                  color="secondary"
                  iconName="icon-bookmark-2-2"
                  title="Secure and trusted"
                >
                  As the world's leading cryptocurrency company, security is always our number one priority.
                </FeatureCard>
              </Col>
              <Col md="3">
                <FeatureCard
                  color="ash"
                  iconName="icon-chat-45-2"
                  title="Great Support"
                >
                  With lots of rate comes from our awesome satisfied customers.
                </FeatureCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Feature;
