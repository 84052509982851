import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import { Box, Text } from "../../components/Core";
import { device } from "../../utils";

import btc from "../../assets/image/svg/btc.svg";
import eth from "../../assets/image/svg/eth.svg";
import trx from "../../assets/image/svg/trx.svg";
import usdt from "../../assets/image/svg/usdt.svg";
import gsmc from "../../assets/image/svg/gsmc.svg";
import bch from "../../assets/image/svg/bch.svg";
import imgg from "../../assets/image/png/4star.png";




const TitleContainer = styled(Box)`
  position: relative;
  &:after {
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 32%;
    background: ${({ theme }) => theme.colors.border};
    margin-top: 0.5px;
    display: none;

    @media ${device.md} {
      width: 40%;
      display: block;
    }
    @media ${device.lg} {
      width: 52%;
    }
    @media ${device.xl} {
      width: 60%;
    }
  }
`;

const Brand = styled(Box)`
  opacity: 0.7;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
  }
`;

const Clients = () => (
    <>
      {/* <!-- Clients Brands Area --> */}
      <Box pb={"60px"}>
        <Container>
          <TitleContainer mb={"50px"}>
            <Text fontSize="18px" as="h4" className="">
              Popular Crypto accept at checkout
            </Text>
          </TitleContainer>
        </Container>
        <Container>
          <Box
              className="d-flex justify-content-center justify-content-lg-between
         align-items-center flex-wrap"
              mx="-32px"
          >
            <Brand className="" py={3} mx={4}>
              <img src={btc} alt="" className="img-fluid" />
            </Brand>
            <Brand className="" py={3} mx={4}>
              <img src={eth} alt="" className="img-fluid" />
            </Brand>
            <Brand className="" py={3} mx={4}>
              <img src={trx} alt="" className="img-fluid" />
            </Brand>
            <Brand className="" py={3} mx={4}>
              <img src={usdt} alt="" className="img-fluid" />
            </Brand>
            <Brand className="" py={3} mx={4}>
              <img src={gsmc} alt="" className="img-fluid" />
            </Brand>
            <Brand className="" py={3} mx={4}>
              <img src={bch} alt="" className="img-fluid" />
            </Brand>
          </Box>
        </Container>
      </Box>
    </>
);

export default Clients;
