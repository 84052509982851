import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import {Title, Section, Box, Text, Button} from "../../components/Core";


import svgHeroShape from "../../assets/image/svg/map.svg";


const SectionStyled = styled(Section)`
  border-bottom: 1px solid #eae9f2;
`;

const ButtonContainer = styled(Box)`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;

const ButtonApp = styled.a`
  padding: 0 9px;
  transition: 0.4s;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    transform: translateY(-8px);
  }
`;

const CTA = () => (
    <>
      <SectionStyled className="position-relative">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="12" xl="12">
              <div className="text-center">
                <Title>Join the Next Generation, world's fastest growing crypto payment network.</Title>

                <Text>
                  Receive payments from anyone in the world.
                </Text>


                  <div style={{marginTop:'100px'}}>
                      <img src={svgHeroShape} alt="" className="img-fluid" />
                  </div>

                  <div className="d-flex mt-5 flex-column align-items-center">
                      <a
                          className="btn-red hvr-bounce-to-left"
                          href={`https://accounts.gsmcoin.com/register`}
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                          <Button mb={3}>Get Started</Button>
                      </a>

                  </div>

              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
);

export default CTA;
